<template>
    <div class="tab_content" id="tab0">
        <form class="formanizr">
            <fieldset v-if="translations.strings">
                <legend ref="top">Personal data</legend>
                <div v-show="Object.keys(formError).length" style="color: red; font-weight: bold; margin-bottom: 10px;" ref="errorBox" >
                    <p v-if="formError.emptyFields">Fields marked with * are mandatory.</p>
                    <p v-if="formError.password">Passwords do not match.</p>
                    <p v-if="formError.passwordFormat">Your password needs to be at least 8 digits long and must contain at least one number and one capital letter.</p>
                    <p v-if="formError.email">Emails do not match.</p>
                    <p v-if="formError.username">This username already exists. Please choose another.</p>
                    <p v-if="formError.terms">Please confirm that you have read and agree to the privacy statement and the terms of use.</p>
                </div>
                <div class="fzr_row fzr_label-top">
                    <div class="fzr_col fzr_4">
                        <label class="mandatory">{{ translations.strings['943'] }}:</label>
                        <input type="text" v-model="formData.username" :disabled="isAuthenticated" autocomplete="new-password">
                    </div>
                    <div class="fzr_col fzr_4" v-if="!isAuthenticated">
                        <label class="mandatory">{{ translations.strings['228'] }}:</label>
                        <v-popover>
                            <input
                                    type="password"
                                    autocomplete="new-password"
                                    v-model="formData.password"
                                    @focus="showTooltip = true"
                                    @blur="showTooltip = false"
                                    v-tooltip="{
                                    template: '',
                                    placement: 'auto',
                                    trigger: 'manual',
                                    show: showTooltip,
                                }"
                            >
                            <template slot="popover">
                                <div>
                                    <p>Password must meet the following requirements:</p>
                                    <ul>
                                        <li>
                                            At least <strong>one capital letter</strong>
                                        </li>
                                        <li>
                                            At least <strong>one number</strong>
                                        </li>
                                        <li>
                                            At least <strong>8 characters</strong>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </v-popover>
                    </div>
                    <div class="fzr_col fzr_4" v-if="!isAuthenticated">
                        <label class="mandatory">{{ translations.strings['10517'] }}:</label>
                        <v-popover>
                            <input
                                    type="password"
                                    autocomplete="new-password"
                                    v-model="formData.password2"
                                    @focus="showTooltip2 = true"
                                    @blur="showTooltip2 = false"
                                    v-tooltip="{
                                    template: '',
                                    placement: 'auto',
                                    trigger: 'manual',
                                    show: showTooltip2,
                                }"
                            >
                            <template slot="popover">
                                <div>
                                    <p>Password must meet the following requirements:</p>
                                    <ul>
                                        <li>
                                            At least <strong>one capital letter</strong>
                                        </li>
                                        <li>
                                            At least <strong>one number</strong>
                                        </li>
                                        <li>
                                            At least <strong>8 characters</strong>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </v-popover>
                    </div>
                </div>
                <div class="fzr_row fzr_label-top">
                    <div class="fzr_col fzr_2">
                        <label>{{ translations.strings['10823'] }}:</label>
                        <select name="Anrede" id="Anrede" v-model="formData.salutation">
                            <option>Please select...</option>
                            <option value="0">{{ translations.strings['9352'] }}</option>
                            <option value="1">{{ translations.strings['6167'] }}</option>
                            <option value="2">{{ translations.strings['6166'] }}</option>
                            <option value="3">{{ translations.strings['10821'] }}</option>
                        </select>
                    </div>
                    <div class="fzr_col fzr_4">
                        <label class="mandatory">{{ translations.strings['234'] }}:</label>
                        <input type="text" v-model="formData.firstname">
                    </div>
                    <div class="fzr_col fzr_4">
                        <label class="mandatory">{{ translations.strings['235'] }}:</label>
                        <input type="text" v-model="formData.lastname">
                    </div>
                    <div class="fzr_col fzr_2">
                        <label>{{ translations.strings['2340'] }}:</label>
                        <input class="fzr_icon-date" placeholder="TT.MM.JJJJ" v-model="formData.birthdate" type="date">
                    </div>
                </div>
            </fieldset>
            <fieldset v-if="translations.strings">
                <div class="fzr_row fzr_label-top">
                    <div class="fzr_col fzr_2">
                        <label class="mandatory">Country:</label>
                        <select v-model="formData.country" v-if="references.country">
                            <option v-for="(option, index) in references.country" :value="option.id" :key="index" >{{option.title}}</option>
                        </select>
                    </div>
                    <div class="fzr_col fzr_4">
                        <label>Address:</label>
                        <input type="text" v-model="formData.address">
                    </div>
                    <div class="fzr_col fzr_2">
                        <label>Postcode:</label>
                        <input type="text" v-model="formData.plz">
                    </div>
                    <div class="fzr_col fzr_4">
                        <label>City:</label>
                        <input type="text" v-model="formData.ort">
                    </div>
                </div>
            </fieldset>
            <fieldset v-if="translations.strings">
                <div class="fzr_row fzr_label-top">
                    <div class="fzr_col fzr_3">
                        <label>Mobile phone:</label>
                        <input type="text" v-model="formData.handy">
                    </div>
                    <div class="fzr_col fzr_3">
                        <label class="mandatory">E-mail:</label>
                        <input type="email" v-model="formData.email">
                    </div>
                    <div class="fzr_col fzr_3">
                        <label class="mandatory">Retype E-mail:</label>
                        <input type="email" v-model="formData.email2">
                    </div>
                    <div class="fzr_col fzr_3" v-if="references.additional_fields.length && references.additional_fields.filter((field) => field.name == 'homepage')">
                        <label>Facebook:</label>
                        <input type="text" v-model="formData.homepage">
                    </div>
                </div>
            </fieldset>
            <fieldset v-if="references.additional_fields.length">
                <div class="fzr_row fzr_label-top">
                    <div class="fzr_col fzr_3" v-for="(field, key) in references.additional_fields.filter((field) => field.name != 'homepage')" :key="key">
                        <label>{{field.text}}:</label>
                        <input type="text" v-model="formData[field.name]">
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <div class="fzr_row fzr_label-top">
                    <div class="fzr_col fzr_3">
                        <label>Whatsapp:</label>
                        <input type="text" v-model="formData.whatsapp">
                    </div>
                    <div class="fzr_col fzr_3">
                        <label class="mandatory">Referral Program Partner:</label>
                        <select v-model="formData.referral_crm_company_id" v-if="references.referral_crm_company" :disabled="isAuthenticated">
                            <option value="">Please select...</option>
                            <option v-for="(option, index) in references.referral_crm_company" :value="option.id" :key="index" >{{option.title}}</option>
                        </select>
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <label for="id_accept_privacy_statement" @click="formData.terms = !formData.terms">
                <input type="checkbox" v-model="formData.terms" style="margin-right: 3px"/>
                I've read and understood the
                    <a href="https://job-server.net/terms_of_use_applicant?sid=wcon_en&amp;no_template=1&amp;no_navigation=1&amp;healthcare=1"
                       target="_blank">data privacy statement</a>
                    and agree that my data are processed by Workconsult and its partner companies go4more.School Language Training Center, 
                    EDI-STAFFBUILDERS INTERNATIONAL INC. as official DMW-licensed Recruitment Partner, 
                    and its DMW-accredited German recruitment agency partner C&C human resource development GmbH for the above mentioned 
                    services. I can revoke my consent at any time, e.g. by e-mail to <a href="mailto:info@workconsult.com">info@workconsult.com</a>.
                </label>
            </fieldset>
            <fieldset v-if="translations.strings">
                <div class="fzr_row fzr_label-top">
                    <div class="fzr_col fzr_12 fzr_right" style="margin-top: 10px">
                        <button @click.prevent="submitForm">Save</button>
                        <button @click.prevent="submitFormAndNext()">{{ translations.strings['10499'] }}</button>
                    </div>
                </div>
            </fieldset>
        </form>
        <StepMenu :step="1"></StepMenu>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { REGISTRATION, LOGIN, UPDATE_USER, CHANGE_TAB, GET_USER } from "@/store/actions.type";
    import StepMenu from "@/components/StepMenu.vue";

    export default {
        name: 'MainForm',
      components: {StepMenu},
        computed: mapGetters(['isAuthenticated', 'error', 'userData', 'references', 'translations', 'activeTab']),
        async mounted() {
            if (this.isAuthenticated) {
                //await this.$store.dispatch(GET_USER);
                this.fillForm();
            }
        },
        watch: {
            userData() {
                this.fillForm();
            },
            references() {
                this.references.additional_fields.map((field) => {
                    if (!this.formData.hasOwnProperty(field.name)) {
                        this.formData[field.name] = '';
                    }
                });
            }
        },

        data() {
            return {
                formData: {
                    username: '',
                    password: '',
                    password2: '',
                    firstname: '',
                    lastname: '',
                    email: '',
                    email2: '',
                    salutation: '',
                    birthdate: '',
                    country: 'PH',
                    address: '',
                    ort: '',
                    plz: '',
                    homepage: '',
                    whatsapp: '',
                    handy: '',
                    terms: false,
                    referral_crm_company_id: '',
                },
                formError: {},
                showTooltip: false,
                showTooltip2: false,
            }
        },
        methods: {
            formPassed() {
                this.formError = {};
                let mandatoryFields = ['firstname', 'lastname', 'email', 'country', 'referral_crm_company_id'];
                if (!this.isAuthenticated) {
                    mandatoryFields.concat(['username', 'password'])
                }
                mandatoryFields.map( (item) => {
                        if (!this.formData[item]) {
                            if (!this.formData.emptyFields) {
                                this.formError.emptyFields = [];
                            }
                            this.formError.emptyFields.push(item);
                        }
                    });
                if (!this.isAuthenticated) {
                    if (!this.formData.password.match(/^(?=.*\d)(?=.*[A-Z]).{8,}$/)) {
                        this.formError.passwordFormat = true;
                    }

                    if (this.formData.password !== this.formData.password2) {
                        this.formError.password = true;
                    }
                }

                if (this.formData.email !== this.formData.email2) {
                    this.formError.email = true;
                }
                if (!this.formData.terms) {
                    this.formError.terms = true;
                }
            },
            async submitForm() {
                this.formPassed();
                if (Object.keys(this.formError).length) {
                    this.$nextTick(() => {
                        const errorBox = this.$refs.top;
                        const position = errorBox.getBoundingClientRect();
                        const windowPos = document.body.getBoundingClientRect()
                        window.scroll({top: position.y - windowPos.y, behavior: 'smooth'})
                    })

                    return false;
                }
                if (this.isAuthenticated) {
                    await this.$store.dispatch(UPDATE_USER, {form_part: 1, ...this.formData});
                } else {
                    await this.$store.dispatch(REGISTRATION, this.formData);
                    if (this.error && this.error.error_code === 31) {
                        this.formError = Object.assign({}, this.formError, {username: true});
                        return false;
                    }
                    await this.$store.dispatch(LOGIN, {username: this.formData.username, password: this.formData.password})
                    await this.$store.dispatch(GET_USER)
                }
                return true;
            },
            async submitFormAndNext() {
                if (await this.submitForm()) {
                    this.$store.dispatch(CHANGE_TAB, this.activeTab + 1);
                }
            },
            fillForm() {
                if (this.isAuthenticated && Object.keys(this.userData)) {
                    let tmp = Object.assign({}, this.formData);
                    Object.keys(this.formData).map( (field) => {
                            tmp[field] = this.userData[field];

                    });
                    if (tmp.email) {
                        tmp.email2 = tmp.email;
                    }
                    tmp.terms = true;
                    this.formData = Object.assign({}, tmp);
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .tooltip {
        display: block !important;
        z-index: 10000;
        font-size: 14px;
    }

    .tooltip .tooltip-inner {
        background: black;
        color: white;
        border-radius: 16px;
        padding: 5px 10px 4px;
    }

    .tooltip .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: black;
        z-index: 1;
    }

    .tooltip[x-placement^="top"] {
        margin-bottom: 5px;
    }

    .tooltip[x-placement^="top"] .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
    }

    .tooltip[x-placement^="bottom"] {
        margin-top: 5px;
    }

    .tooltip[x-placement^="bottom"] .tooltip-arrow {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
    }

    .tooltip[x-placement^="right"] {
        margin-left: 5px;
    }

    .tooltip[x-placement^="right"] .tooltip-arrow {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
    }

    .tooltip[x-placement^="left"] {
        margin-right: 5px;
    }

    .tooltip[x-placement^="left"] .tooltip-arrow {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
    }

    .tooltip.popover .popover-inner {
        background: #f9f9f9;
        color: black;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(black, .1);
    }

    .tooltip.popover .popover-arrow {
        border-color: #f9f9f9;
    }

    .tooltip[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity .15s, visibility .15s;
    }

    .tooltip[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity .15s;
    }

    .v-popover .trigger {
        width: 100%;
    }
</style>
