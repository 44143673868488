<template>
  <div>
  <header>
    <div class="inner">
      <div class="logo"><a href="https://workconsult.com/">
        <picture>
          <img
              srcset="https://workconsult.com/wp-content/themes/workconsult/assets/images/logo.png, https://workconsult.com/wp-content/themes/workconsult/assets/images/logo@2x.png 2x"
              alt="Work consultant">
        </picture>
      </a></div>
      <nav class="main-menu" v-if="translations.strings">
        <li><a href="https://workconsult.com/#win-win">Training</a></li>
        <li><a href="https://workconsult.com/#solutions">Partner Solutions</a></li>
        <li><a href="https://healthcare.workconsult.com/">HEALTHCARE</a></li>
        <li><a href="https://workconsult.com/#about-us">About Us</a></li>
        <li><a href="https://workconsult.com/#contact">Contact</a></li>
      </nav>
      <section class="support-section">
        <a class="mobile-menu" href="javascript:void(0);">
          <span class="hamburger"></span>
        </a>
        <a class="login" href="#" @click.prevent="showLoginPopup = true">
          <picture>
            <img
                srcset="https://workconsult.com/wp-content/themes/workconsult/assets/images/user-login-icon.png, https://workconsult.com/wp-content/themes/workconsult/assets/images/user-login-icon@2x.png 2x"
                alt="">
          </picture>
        </a>
        <nav class="language-switcher">
          <li><a href="/" class="active">
            <picture>
              <img
                  srcset="https://workconsult.com/wp-content/themes/workconsult/assets/images/flag-gb-icon.png, https://workconsult.com/wp-content/themes/workconsult/assets/images/flag-gb-icon@2x.png 2x"
                  alt="">
            </picture>
            <div class="name"></div>
          </a>
            <ul>
              <li><a href="https://workconsult.ua">
                <picture>
                  <img
                      srcset="https://workconsult.com/wp-content/themes/workconsult/assets/images/flag-ukr-icon.png, https://workconsult.com/wp-content/themes/workconsult/assets/images/flag-ukr-icon@2x.png 2x"
                      alt="">
                </picture>
                <div class="name">Українська</div>
              </a></li>
              <li><a href="https://workconsult.de">
                <picture>
                  <img
                      srcset="https://workconsult.com/wp-content/themes/workconsult/assets/images/flag-de-icon.png, https://workconsult.com/wp-content/themes/workconsult/assets/images/flag-de-icon@2x.png 2x"
                      alt="">
                </picture>
                <div class="name">Deutsch</div>
              </a></li>
            </ul>
          </li>
        </nav>
      </section>
      <section class="login-form" v-if="showLoginPopup">
        <div class="bg" @click="showLoginPopup = false"></div>
        <div class="login-container" :class="{'login-variation-select': !showLoginVariant, 'register-variation-select': showLoginVariant}">
            <div class="close-area">
                <a href="#" class="close" @click="showLoginPopup = false"></a>
            </div>
            <h3></h3>
            <div class="login-area">
                <div class="register-select">
                    <ul class="login-variant" v-if="showLoginVariant">
                        <li><a class="first-login" href="/jobnet/ang/specialist.php?sid=wcon_en" @click.prevent="openWorkconsult">Jobseekers' Login</a>
                        </li>
                        <li><a class="second-login" href="/jobnet/ang/employer.php?sid=wcon_en" @click.prevent="openWorkconsult">Employers' Login</a>
                        </li>
                        <li><a class="third-login" href="/jobnet/vermittler.php?sid=wcon_en" @click.prevent="openWorkconsult">Consultants' Login</a>
                        </li>
                    </ul>
                    <ul class="register-variant" v-else>
                        <li><a class="first-register" href="/jobnet/ang/spec_edit_lite.php?action=new&amp;sid=wcon_en" @click.prevent="openWorkconsult">BewerberProfil
                                Registrieren</a></li>
                        <li><a class="second-register" href="/jobnet/ang/employer.php?step=3&amp;sid=wcon_en" @click.prevent="openWorkconsult">ArbeitgeberProfil
                                Registrieren</a></li>
                    </ul>
                </div>


            </div>
            <p class="register-link" v-if="showLoginVariant">Don't have an account? <a href="#" @click="showLoginVariant = false">Register</a></p>
            <p class="login-link" v-else>Already have an account? <a href="#" @click="showLoginVariant = true">Login</a></p>
        </div>
    </section>
    </div>
  </header>
  <!-- /Header -->


  <!-- #page -->
  <div class="page">
    <!-- #page -->


    <!-- Intro -->
    <div class="container contentcontainer">
      <div class="row clearfix">
        <div class="twelvecol last">
          <div v-if="translations.strings" class="content">
            <h1>{{ translations.strings['10492'] }}
              <br/><span class="light">
                                In co-operation with go4more.School Language Training Center,
								EDI-STAFFBUILDERS INTERNATIONAL INC., and C&C human resource development GmbH</span></h1>
            <!-- <p>In order to avail of the free German language training for nurses, provided online and on premises by our
              sister company go4more.School, please fill out and submit the registration form accurately. When saving
              the first data sheet, a free candidate account will be created automatically for you, which you can update
              and also delete at any time.<br/>
              Please do also select the matching Referral Program Partner.
              For all questions concerning recruitment and deployment for Germany, please contact our official
              Recruitment Partner EDI-STAFFBUILDERS INTERNATIONAL INC. with POEA License No. POEA-040-LB-061914-PA and
              main office in Units 701, 703 & 704 & 802, 139 Corporate Center Building, 139 Valero St., Salcedo Village,
              Makati City, 1227 Philippines. Phone: <a href="tel:+8-812-6703">+8 812-6703</a> or <a
                  href="tel:+8-812-6704">+8 812-6704</a>. Email: <a href="mailto:smdg@edistaffbuilders.com">smdg@edistaffbuilders.com</a>.
              <br/><br/>
              After registration, one of our consultants will contact you and inform you about the possible next steps.
              <br/>
              Once you're approved and selected for the German language education program, your account will give you
              access to the online learning management system with course materials etc.</p> -->
              <p v-text="translations.strings['10494']"></p>
            <div class="row clearfix hc-company-logo-container ">
              <div class="fourcol hc-company-logo">
                <div>
                  <a href="https://ph.go4more.school" target="_blank"><img alt="go4more.School"
                                                                           src="/images/go4more.png"
                                                                           class="go4more"></a>
                </div>
              </div>
              <div class="fourcol hc-company-logo">
                <div>
                  <a href="https://edistaffbuilders.com" target="_blank"><img alt="EDI-STAFFBUILDERS INTERNATIONAL INC"
                                                                              src="/images/edi_small_orig.png"></a>
                </div>
              </div>
              <div class="fourcol hc-company-logo">
                <div>
                  <a href="http://cc-hrd.de" target="_blank"><img alt="C&C HUMAN RESOURCE DEVELOPMENT"
                                                                  src="/images/C&C LOGOTrans.png"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Intro -->
    <!-- Content -->
    <div id="app">
      <FormView/>
    </div>
    <!-- /Content -->
  </div>
  <!-- / #page -->

  <!-- Footer -->
  <footer>
    <div class="inner">

      <div class="cols">

<h3>IMPRINT</h3>
<p><b>Workconsult Training Corporation</b><br>
Rue Dufetel, Fonte di Versailles<br>
Minglanilla, Cebu<br>
6046, Philippines</p>
<p><i class="bi bi-envelope me-2"></i><a href="mailto:info@workconsult.com">info@workconsult.com</a><br>
<i class="bi bi-globe2 me-2"></i><a href="https://workconsult.com">workconsult.com</a></p>
<p>Company Reg. No.: 2023040096442-03<br>
TIN 626-489-399-00000
</p>
<p><b><a href="#" onclick="window.open('https://job-server.net/terms_of_use_applicant?sid=wcon_en&amp;no_template=1&amp;no_navigation=1','Hilfe','scrollbars=1,resizable=1,width=450,height=500,left=50,top=50');return false;">Data privacy</a></b></p>
<p><a id="pr-policy" class="pum-trigger" style="cursor: pointer;">Privacy policy</a></p>

</div>

      <div class="cols">
          <h3>Disclaimer</h3>
          <p style="text-align: left;">This website contains among others resumes and job ads. Workconsult is not liable for the accuracy of the information therein.</p>
      <p style="text-align: left;">Several parts of this website can have links to other websites in the internet. We would like to point out that exclusively the provider of the linked websites is responsible for their content. We are not liable for the content of any linked website and distance ourselves from their content. Users follow links at their own risk and use linked websites according to their respective terms and conditions.</p>
      <p style="text-align: left;">Workconsult uses cookies to enable the navigation within the password-protected parts of the system, to recognize general preferences of visitors and by this improve the website. Cookies are small text files which are being saved by your browser; they are no programs. By using cookies nobody else has access to your personal data. You can activate or deactivate cookies at any time in your browser. If the cookies are disabled in your browser, this may influence the use of this website.</p>
      <p style="text-align: left;">We are not liable that all information on this website is up-to-date, accurate and complete. We are not liable for direct or indirect damages, including missed profit, which may be incurred by information provided on this website.</p>
      </div>

      <div class="cols">

          <h3>Social Media</h3>

        
          <ul class="social">

              <li>

                  <a class="you-tube" target="_blank" href="https://www.youtube.com/channel/UC_1JlkOsng-34R2LPdEJfjQ"></a>

              </li>


              <li>

                  <a class="fb" target="_blank" href="https://www.facebook.com/workconsult.ukraine"></a>

              </li>


              <li>

                  <a class="instagram" target="_blank" href="https://www.instagram.com/workconsult.ukraine/"></a>

              </li>

          </ul>
                                              </div>

      <div class="bottom-dark"></div>

      </div>
  </footer>
  <!-- /Footer -->
    </div>
</template>

<script>
  import FormView from './views/FormView.vue'
  import {GET_USER, CHECK_AUTH, GET_REFERENCES, GET_FILES_LIST, GET_TRANSLATIONS} from "./store/actions.type";
  import {mapGetters} from 'vuex';

  export default {
    name: 'app',
    computed: mapGetters(['isAuthenticated', 'isUserFetching', 'translations']),
    data: () => ({
      showLoginPopup: false,
      showLoginVariant: true,
    }),
    async created() {
      await this.$store.dispatch(GET_TRANSLATIONS);
      await this.$store.dispatch(GET_REFERENCES);
      if (this.isAuthenticated) {
        await this.$store.dispatch(CHECK_AUTH);
        await this.$store.dispatch(GET_USER);
        await this.$store.dispatch(GET_FILES_LIST);
      }
    },
    components: {
      FormView
    },
    methods: {
      openWorkconsult($event) {
        const fs = document.createElement('frameset');
        fs.rows = '0,*';
        fs.setAttribute('border', '0');
        fs.setAttribute('frameborder', '0');
        fs.setAttribute('framespacing', '0');
        fs.innerHTML = `<frame name="j_blank" scrolling="no" resize="no">
        <frame name="j_mainframe" src="${$event.target.href}"">`;
        document.documentElement.appendChild(fs);
        document.head.innerHtml = ''
        document.body.remove();
      }
    }
  }
</script>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .3s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
