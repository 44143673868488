import {
    SET_USER_QUESTIONS
} from './mutations.type';


const state = {
    questions: {},
};


const getters = {
    questions(state) {
        return state.questions;
    },
};

const actions = {

};

const mutations = {
    [SET_USER_QUESTIONS](state, data) {
        state.questions = data;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
