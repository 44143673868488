<template>
    <label class="skill-level-stars-wrapper">
        <v-popover
                popover-arrow-class="tooltip-arrow popover-arrow skill-level-popover-arrow"
                popover-inner-class="tooltip-inner popover-inner skill-level-popover"
                @auto-hide="onPopoverHide"
        >
            <div style="display: flex; align-items: baseline">
                <span class="align-helper"></span>
                <img class="skill-level-stars" src="/images/0stars.png" v-if="!value">
                <img class="skill-level-stars" src="/images/1stars.png" v-if="value === 1">
                <img class="skill-level-stars" src="/images/2stars.png" v-if="value === 2">
                <img class="skill-level-stars" src="/images/3stars.png" v-if="value === 3">
                <img class="skill-level-stars" src="/images/4stars.png" v-if="value === 4">

                <div class="skill-title">{{title}}</div>
            </div>
            <SkillLevel slot="popover" :resetStars="resetStars" @setSkill="updateSkill($event)" :skillLevel="value"/>
        </v-popover>
    </label>
</template>

<script>
    import SkillLevel from './SkillLevel';

    export default {
        name: "SkillContainer",
        components: {SkillLevel},
        props: ['value', 'skillId', 'title'],
        data() {
            return {
                resetStars: false,
            }
        },
        methods: {
            updateSkill(value) {
                this.$emit('updateSkill', value)
            },
            onPopoverHide() {
                //dirty hacks for reset stars in popover
                this.resetStars = true;
                this.$nextTick(() => {
                    this.resetStars = false;
                })
            },
        }
    }
</script>

<style>
    .skill-level-popover {
        background-color: #f4f2e0 !important;
    }

    .skill-level-popover-arrow {
        border-color: #f4f2e0 !important;
    }

    .align-helper {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

    .skill-level-stars {
        vertical-align: baseline;
        margin-right: 3px;
        flex: 0 0 auto;
    }

    .skill-level-stars-wrapper {
        cursor: pointer;
    }

    .skill-title {
        margin-left: 50px;
    }
</style>
