<template>
    <div class="tab_content" id="tab3">
        <section v-if="translations.strings" style="margin-bottom: 30px;" class="formanizr">
            <legend v-text="translations.strings['10516']" />
            <p>Please click on the button „Open Job Orientation“ and follow the job orientation carefully. If after reading the complete job orientation you still have questions, please use the „My questions“ field below.</p>
            <p>We’ll contact you as soon as possible. Thanks!</p>
            <!-- <button @click.prevent="openCourse" style="margin-top: 10px">Open Job Orientation</button> -->
            <button @click="[openCourse(), courseModalShow = !courseModalShow]" style="margin-top: 10px">Open Job Orientation</button>
        </section>
        <form v-if="translations.strings" class="formanizr">
            <legend>My questions</legend>
            <textarea
                style="margin-bottom: 16px"
                v-model="questionText"
                cols="30"
                rows="10"
            ></textarea>
            <fieldset>
                <div class="fzr_row fzr_label-top">
                    <div class="fzr_col fzr_12 fzr_right">
                        <button @click.prevent="submitForm">Save</button>
                        <button @click.prevent="submitFormAndNext()" v-text="translations.strings['10499']"></button>
                    </div>
                </div>
            </fieldset>
        </form>
        <form
            name="autologForm"
            target="autologFrame"
            method="post"
            :action="courseUrl"
        >
            <input type="hidden" name="codename" :value="moodleData.username">
            <input type="hidden" name="key" :value="moodleData.password">
            <input type="hidden" name="redirect" value="course/view.php?id=123&popup=1">
        </form>
        <transition name="fade">
            <div v-if="translations.strings" class="modal" v-show="courseModalShow">
                <div class="modal-overlay" @click="courseModalShow = false"></div>
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 class="modal-title">German Program – {{ translations.strings['10516'] }}</h2>
                        <button @click="courseModalShow = false" class="modal-close">&#10006;</button>
                    </div>
                    <iframe name="autologFrame" frameborder="0"></iframe>
                </div>
            </div>
        </transition>
      <StepMenu :step="4"></StepMenu>
    </div>
</template>

<script>
import {UPDATE_USER, CHANGE_TAB, START_MOODLE_COURSE} from '../store/actions.type';
import { mapGetters } from 'vuex';
import StepMenu from "@/components/StepMenu.vue";
export default {
    name: 'JobOrientation',
  components: {StepMenu},
    data: () => ({
        questionText: '',
        courseModalShow: false,
        courseUrl: `${process.env.VUE_APP_MOODLE_SERVER ? process.env.VUE_APP_MOODLE_SERVER : 'https://dev.go4more.school'}/autolog.php`
    }),
    computed: mapGetters(['questions', 'activeTab', 'moodleData', 'translations']),
    methods: {
        async openCourse() {
            const form = document.forms['autologForm']
            form.submit()
            this.startCourse()
        },
        async startCourse() {
            this.$store.dispatch(START_MOODLE_COURSE);
        },
        async submitForm() {
            this.$store.dispatch(UPDATE_USER, {form_part: 5, textMessage: this.questionText});
            return true;
        },
        async submitFormAndNext() {
            if (await this.submitForm()) {
                this.$store.dispatch(CHANGE_TAB, this.activeTab + 1);
            }
        },
    },
}
</script>
<style lang="scss">
    .modal {
        display: flex;
        position: fixed;
        justify-content: center;
        align-items: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 998;

        &-overlay {
            position: absolute;
            background-color: rgba(23, 23, 23, .7);
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        &-header {
            display: flex;
            background-color: #fff;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 1rem;
        }

        &-title {
            margin-bottom: 0;
            font-weight: normal;
            color: #585858;
        }

        &-content {
            position: relative;
            background-color: #fff;
            width: 90vw;
            height: 90vh;
            border-radius: .375rem;
            padding: 1rem;
            box-sizing: border-box;

            iframe {
                width: 100%;
                height: calc(100% - 60px);
            }
        }

        &-close {
            background-color: transparent;
            padding: 0.5rem;
            font-size: 1.5rem;
            cursor: pointer;
            color: #585858;
            border: 0;
        }
    }
</style>