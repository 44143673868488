<template>
    <div class="tab_content" id="tab1">
        <form class="formanizr">
            <fieldset v-if="translations.strings" style="margin-bottom: 35px;">
                <legend v-text="translations.strings['10500']" />
                <p style="margin-bottom: 20px;" v-text="translations.strings['10501']" />
                <p>{{ translations.strings['10502'] }}</p>
                <div class="section" ref="editDiv">
                    <div class="fzr_row fzr_label-top">
                        <div class="fzr_col fzr_2">
                            <label>from:</label>
                            <input class="fzr_icon-date" placeholder="TT.MM.JJJJ" v-model="formData.date_from" type="date">
                        </div>
                        <div class="fzr_col fzr_2">
                            <label>{{ translations.strings['10518'] }}:</label>
                            <input class="fzr_icon-date" placeholder="TT.MM.JJJJ" v-model="formData.date_to" type="date">
                        </div>
                        <div class="fzr_col fzr_4">
                            <label>{{ translations.strings['10503'] }}:</label>
                            <input type="text" value="" v-model="formData.position">
                        </div>
                        <div class="fzr_col fzr_4">
                            <label>{{ translations.strings['10504'] }}:</label>
                            <select v-model="formData.section">
                                <option value="">Please select...</option>
                                <option v-for="(option, index) in employmentType" :value="option.value" :key="index">{{option.title}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="fzr_row fzr_label-top">
                        <div class="fzr_col fzr_6">
                            <label>Employer:</label>
                            <input type="text" value="" v-model="formData.firmname">
                        </div>
                        <div class="fzr_col fzr_6">
                            <label>Workplace:</label>
                            <input type="text" value="" v-model="formData.location">
                        </div>
                    </div>
                    <div class="fzr_row fzr_label-top">
                        <div class="fzr_col fzr_6">
                            <label>{{ translations.strings['10506'] }}:</label>
                            <textarea id="textarea_1" v-model="formData.description"></textarea>
                        </div>
                    </div>
                    <div class="fzr_row">
                        <div class="fzr_col fzr_12 fzr_right">
                            <span style="color: red;font-weight: bold;" v-if="dateError">Please enter valid dates.</span>
                            <button class="experience-save-entry-button" @click.prevent="saveEntry" :disabled="fakeDate">
                                {{ translations.strings['10509'] }}
                            </button>
                        </div>
                    </div>
                </div>
                <table class="datatable" v-show="cv.length">
                    <tbody>
                    <tr>
                        <th style="">from:</th>
                        <th style="">{{ translations.strings['10518'] }}:</th>
                        <th style="">{{ translations.strings['10503'] }}:</th>
                        <th>{{ translations.strings['10504'] }}:</th>
                        <th>Employer:</th>
                        <th>Workplace:</th>
                        <th>{{ translations.strings['10506'] }}:</th>
                        <th></th>
                    </tr>
                    <tr v-for="(record, index) in cv.filter( (item,) => item.key !== this.formData.key)" :key="index">
                        <td>{{record.date_from ? formatDate(record.date_from) : ''}}</td>
                        <td>{{record.date_to ? formatDate(record.date_to) : ''}}</td>
                        <td>{{record.position}}</td>
                        <td>{{employmentType.filter((option) => option.value === record.section) && record.section ? employmentType.filter((option) => option.value === record.section)[0].title : ''}}</td>
                        <td>{{record.firmname}}</td>
                        <td>{{record.location}}</td>
                        <td>{{record.description}}</td>
                        <td style="width: 130px;">
                            <button class="deletedata" @click.prevent="deleteEntry(record.key)">Delete entry</button>
                            <button class="editdata" @click.prevent="editEntry(record.key)">Edit</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </fieldset>
            <fieldset v-if="translations.strings">
                <div class="section">
                    <div class="fzr_row fzr_label-top">
                        <div class="fzr_col fzr_12">
                            <p>You can upload here documents such as CV, certificates or photos and send them as an attachment to e-mail applications if required.</p>
                            <div class="upload-form-wrapper">
                                <ul v-if="filesList && filesList.length">
                                    <li v-for="(file, index) in filesList" :key="index">
                                        <label class="uploaded-file-wrapper"><input type="checkbox" :value="file.filename" v-model="filesToDelete"> <span>{{file.filename}} {{`${parseInt(file.size / 1024)} KB`}}</span></label>
                                    </li>
                                    <li><input type="button" value="Delete" @click.prevent="deleteFiles"></li>
                                </ul>
                                <label>{{ translations.strings['10507'] }}:</label>
                                <input type="file" ref="file" @change="onFileSelect">
                                <button @click.prevent="uploadFile">Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            <fieldset v-if="translations.strings">
                <div class="section">
                    <div class="fzr_row fzr_label-top">
                        <div class="fzr_col fzr_12 fzr_right">
                            <button @click.prevent="submitForm">Save</button>
                            <button @click.prevent="submitFormAndNext()" v-text="translations.strings['10499']"></button>
                        </div>
                    </div>
                </div>
            </fieldset>
            <StepMenu :step="2"></StepMenu>
        </form>
    </div>
</template>

<script>
    import { ADD_CV, DELETE_CV_ENTRY, UPDATE_USER, CHANGE_TAB, UPLOAD_FILE, DELETE_FILES } from "../store/actions.type";
    import { mapGetters } from 'vuex';
    import StepMenu from "@/components/StepMenu.vue";

    const formDataInit = {
        date_from: '',
        date_to: '',
        position: '',
        section: '',
        firmname: '',
        location: '',
        description: '',
    };

    export default {
        name: "CVForm",
      components: {StepMenu},
        computed: {
            fakeDate() {
                return !this.formData.date_from ||
                    !!this.formData.date_to && (this.formData.date_from > this.formData.date_to);
            },
            ...mapGetters(['cv', 'activeTab', 'filesList', 'isAuthenticated', 'translations'])
        },
        data() {
            return {
                formData: Object.assign({}, formDataInit),
                employmentType: [
                    {value: 1, title: "Work experience"},
                    {value: 2, title: "Vocational training/ University"},
                    {value: 3, title: "Training/ Internship"},
                    {value: 4, title: "Education"},
                    {value: 0, title: "Others"},
                ],
                uploadDoc: '',
                filesToDelete: [],
                dateError: false,
            }
        },
        methods: {
            async saveEntry() {
                this.dateError = false;
                this.checkDate();
                if (this.dateError) {
                    return;
                }
                this.$store.dispatch(ADD_CV, this.formData);
                this.formData = Object.assign({}, formDataInit);
                await this.submitForm();
            },
            editEntry(key) {
                this.formData = Object.assign({}, this.cv.filter((record) => record.key === key)[0]);
                const editDiv = this.$refs.editDiv;
                const position = editDiv.getBoundingClientRect();
                const windowPos = document.body.getBoundingClientRect();
                window.scroll({top: position.y - windowPos.y, behavior: 'smooth'})
            },
            deleteEntry(key) {
                this.$store.dispatch(DELETE_CV_ENTRY, key);
            },
            async submitForm() {
                this.$store.dispatch(UPDATE_USER, {form_part: 2, cv: this.cv});
                return true;
            },
            async submitFormAndNext() {
                if (await this.submitForm()) {
                    this.$store.dispatch(CHANGE_TAB, this.activeTab + 1);
                }
            },
            async uploadFile() {
                if (!this.uploadDoc) {
                    return;
                }
                await this.$store.dispatch(UPLOAD_FILE, this.uploadDoc);
                this.uploadDoc = '';
                this.$refs.file.type = 'text'; // hack for reset input file
                this.$refs.file.type = 'file';
            },
            async deleteFiles() {
                if (!this.filesToDelete.length) {
                    return;
                }
                await this.$store.dispatch(DELETE_FILES, this.filesToDelete);
                this.filesToDelete = [];
            },
            onFileSelect() {
                this.uploadDoc = this.$refs.file.files[0];
            },
            formatDate(data) {
                const tmp = new Date(data);
                const month = String(tmp.getMonth()).length === 2 || tmp.getMonth() === 9 ?
                    tmp.getMonth() + 1
                    : '0' + String(tmp.getMonth() + 1);
                const date = String(tmp.getDate()).length === 2 ? tmp.getDate() : '0' + String(tmp.getDate());
                return `${date}.${month}.${tmp.getFullYear()}`;
            },
            checkDate() {
                ['date_from', 'date_to'].map((field) => {
                    if (this.formData[field]) {
                        const tmpDate = new Date(this.formData[field]);
                        this.dateError = tmpDate.getFullYear() < 1900 || tmpDate.getFullYear() > 2100;
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .upload-form-wrapper input[type="file"] {
        max-width: 300px;
    }

    .uploaded-file-wrapper {
        cursor: pointer;
    }
</style>
