
import {

} from './actions.type';
import {
    SET_SKILLS,
    SET_SKILL_VALUE,
} from './mutations.type';
import {UPDATE_SKILL} from './actions.type';


const state = {
    skills: {},
};


const getters = {
    skills(state) {
        return state.skills;
    },
};

const actions = {
    [UPDATE_SKILL](context, data){
        context.commit(SET_SKILL_VALUE, data);
    }
};

const mutations = {
    [SET_SKILLS](state, data) {
        state.skills = data;
    },
    [SET_SKILL_VALUE](state, data) {
        state.skills = Object.assign({}, state.skills, {[data.skillId]: data.value});
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
