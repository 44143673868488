import Vue from 'vue'
import App from './App.vue';
import store from '@/store';
import ApiService from '@/common/api.service';
import VTooltip from 'v-tooltip'

Vue.use(VTooltip);

Vue.config.productionTip = false;

ApiService.init();

new Vue({
  el: '#app',
  store,
  render: h => h(App),
})
