<template slot="popover">
    <div style="cursor: pointer;">
        <img title="Diese Bewertung abbrechen" class="raty-cancel" src="/images/cancel-on.png" v-close-popover alt="x" @mouseover="star = 0" @click="setSkill">&nbsp;
        <img alt="1" :src="star > 0 ? '/images/icon-star-gold.png' : '/images/icon-star-white.png'" v-close-popover title="Grundkenntnisse " @mouseover="star = 1" @click="setSkill">&nbsp;
        <img alt="2" :src="star > 1 ? '/images/icon-star-gold.png' : '/images/icon-star-white.png'" v-close-popover title="gute Grundkenntnisse" @mouseover="star = 2" @click="setSkill">&nbsp;
        <img alt="3" :src="star > 2 ? '/images/icon-star-gold.png' : '/images/icon-star-white.png'" v-close-popover title="gute Kenntnisse" @mouseover="star = 3" @click="setSkill">&nbsp;
        <img alt="4" :src="star > 3 ? '/images/icon-star-gold.png' : '/images/icon-star-white.png'" v-close-popover title="sehr gute Kenntnisse" @mouseover="star = 4" @click="setSkill">
    </div>
</template>




<script>
    export default {
        name: "SkillLevel",
        props: ['skillLevel', 'resetStars'],
        data() {
            return {
                star: 0,
            }
        },
        methods: {
            setSkill() {
                this.$emit('setSkill', this.star)
            }
        },
        watch: {
            skillLevel() {
                this.star = this.skillLevel;
            },
            resetStars() {
                this.star = this.skillLevel;
            },
        }
    }
</script>

<style scoped>

    img {
        vertical-align: middle;
    }

</style>
