import ApiService from '@/common/api.service';
import JwtService from '@/common/jwt.service';
import {
    GET_USER,
    LOGIN,
    REFRESH_TOKEN,
    REGISTRATION, CHECK_AUTH, UPDATE_USER, GET_REFERENCES, CHANGE_TAB, LOGOUT,
    START_MOODLE_COURSE,
    GET_TRANSLATIONS
} from './actions.type';
import {
    SET_AUTH,
    PURGE_AUTH,
    SET_ERROR,
    FETCH_START,
    FETCH_END,
    SET_USER,
    FETCH_USER_INFO_START,
    FETCH_USER_INFO_END,
    SET_REFERENCES,
    SET_TAB,
    SET_CV,
    SET_SKILLS,
    SET_USER_QUESTIONS,
    SET_MOODLE_DATA,
    SET_TRANSLATIONS,
} from './mutations.type';


const state = {
    isAuthenticated: !!JwtService.getToken(),
    isLoading: false,
    isUserLoading: false,
    error: null,
    user: {
        info: {},
        cv: [],
        skills: {},
        questions: {},
    },
    moodle: {},
    references: {
        skills: {
            language: [],
            dl: [],
            competence: {},
        },
        referral_crm_company: [],
        additional_fields: [],
    },
    translations: {},
    activeTab: 0
};

const initialUserState = {...state.user};


const getters = {
    isAuthenticated() {
        return state.isAuthenticated;
    },
    error(state) {
        return state.error;
    },
    userData(state) {
        return state.user
    },
    moodleData(state) {
        return state.moodle
    },
    isUserFetching(state) {
        return state.isUserLoading;
    },
    isLoading(state) {
        return state.isLoading;
    },
    references(state) {
        return state.references;
    },
    translations(state) {
        return state.translations;
    },
    activeTab(state) {
        return state.activeTab;
    }
};

const actions = {
    async [LOGIN](context, data) {
        try {
            const resp = await ApiService.post('/login', {type: 'healthcare', ...data});
            context.commit(SET_AUTH, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e);
        }
    },
    async [REFRESH_TOKEN](context, token) {
        try {
            const resp = await ApiService.put('/refresh_token', {type: 'healthcare', token});
            context.commit(SET_AUTH, resp.data);
            return 0;
        } catch (e) {
            context.commit(SET_ERROR, e);
            return 1;
        }
    },
    async [REGISTRATION](context, data) {
        context.commit(FETCH_START);
        try {
            await ApiService.post('/user/healthcare/registration', data);
        } catch (e) {
            context.commit(SET_ERROR, e.response);
        }
        context.commit(FETCH_END);
    },
    async [UPDATE_USER](context, data) {
        context.commit(FETCH_START);
        try {
            await ApiService.post('/user/healthcare/update', data);
        } catch (e) {
            context.commit(SET_ERROR, e.response);
        }
        context.commit(FETCH_END);
    },
    async [GET_USER](context) {
        context.commit(FETCH_USER_INFO_START);
        try {
            const resp = await ApiService.query('/user/healthcare/info');
            context.commit(SET_USER, resp.data.info);
            context.commit(SET_CV, resp.data.cv);
            context.commit(SET_SKILLS, resp.data.skills);
            context.commit(SET_USER_QUESTIONS, resp.data.questions);
            context.commit(SET_MOODLE_DATA, resp.data.moodle);
        } catch (e) {
            context.commit(SET_ERROR, e.response);
        }
        context.commit(FETCH_USER_INFO_END);
    },
    async [GET_TRANSLATIONS](context) {
        context.commit(FETCH_START);
        try {
            const resp = await ApiService.query('/user/healthcare/translations');
            context.commit(SET_TRANSLATIONS, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e.response);
        }
        context.commit(FETCH_END);
    },
    async [GET_REFERENCES](context) {
        context.commit(FETCH_START);
        try {
            const resp = await ApiService.query('/user/healthcare/references');
            context.commit(SET_REFERENCES, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e.response);
        }
        context.commit(FETCH_END);
    },
    async [START_MOODLE_COURSE](context) {
        try {
            await ApiService.post('/user/healthcare/set_course/start')
        } catch (e) {
            context.commit(SET_ERROR, e.response);
        }
    },
    [LOGOUT](context) {
        context.commit(PURGE_AUTH)
    },
    [CHECK_AUTH]() {
        if (JwtService.getToken()) {
            ApiService.setHeader();
        }
    },
    [CHANGE_TAB](context, tab) {
        context.commit(SET_TAB, tab)
    }
};

const mutations = {
    [FETCH_START](state) {
        state.isLoading = true;
        state.error = null;
    },
    [FETCH_END](state) {
        state.isLoading = false;
    },
    [SET_ERROR](state, data) {
        state.error = data.data;
    },
    [SET_AUTH](state, data) {
        state.isAuthenticated = true;
        JwtService.saveToken(data);
        ApiService.setHeader();
    },
    [SET_USER](state, data) {
        state.user = data;
    },
    [PURGE_AUTH](sState) {
        sState.isAuthenticated = false;
        sState.user = {...initialUserState};
        sState.activeTab = 0;
        JwtService.destroyToken();
    },
    [FETCH_USER_INFO_START](sState) {
        sState.isUserLoading = true;
    },
    [FETCH_USER_INFO_END](sState) {
        sState.isUserLoading = false;
    },
    [SET_REFERENCES](state, data) {
        state.references = data;
    },
    [SET_TRANSLATIONS](state, data) {
        state.translations = data;
    },
    [SET_MOODLE_DATA](state, data) {
        state.moodle = data;
    },
    [SET_TAB](state, tab) {
        state.activeTab = tab;
    }
};

export default {
    state,
    actions,
    mutations,
    getters,
};
