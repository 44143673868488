/* eslint no-console: 0 */
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/common/jwt.service';
import store from '@/store';
import {REFRESH_TOKEN, LOGOUT} from '@/store/actions.type';
import {domainToSid} from "./app.config";


const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        if (process.env.VUE_APP_API_SERVER) {
            Vue.axios.defaults.baseURL = process.env.VUE_APP_API_SERVER
        } else {
            Vue.axios.defaults.baseURL = `/v1`;
        }

        Vue.axios.interceptors.request.use(async (config) => {
            const newParams = { ...config.params };
            newParams.sid = this.getSid();
            config.params = newParams;
            if (config.url.indexOf('/registration') > -1 || config.url.indexOf('/login') > -1 ||
                config.url.indexOf('/refresh_token') > -1 || config.url.indexOf('/references') > -1 ||
                config.url.indexOf('/translations') > -1) {
                return config;
            }
            const diffTime = JwtService.getTimeFromToken(JwtService.getToken()) - Math.round(Date.now() / 1000);
            let setLogout = false;
            if (diffTime < 60) {
                if (JwtService.refreshExp() > 0 ) {
                    try {
                        config = await this.refreshToken(config);
                    } catch (e) {
                        setLogout = true;
                    }
                } else {
                    setLogout = true;
                }
            }
            if (process.env.VUE_APP_API_DEBUG_PARAM) {
                if (config.method === 'get') {
                    const newParams = Object.assign({}, config.params);
                    newParams.debug = 1;
                    config.params = newParams;
                } else if (config.method !== 'delete') {
                    config.data.debug = 1;
                }
            }
            if (setLogout) {
                await store.dispatch(LOGOUT);
                window.location = '/';
            }
            return config;
        });

        Vue.axios.interceptors.response.use((response) => {
            return response;
        }, async (error) => {
            const { response: { status }, config } = error;
            if (config.url.indexOf('/refresh_token') > -1 || config.url.indexOf('/registration') > -1 ||
                config.url.indexOf('/office_function') > -1 || config.url.indexOf('/password_recovery') > -1) {
                return Promise.reject(error);
            }
            if (status === 401 ) {
                await store.dispatch(LOGOUT);
                return new Promise((resolve) => {
                    resolve(axios(config));
                });
            }
            return Promise.reject(error);
        });
    },

    setHeader() {
        Vue.axios.defaults.headers.common.Authorization = `Bearer ${JwtService.getToken()}`;
    },

    async query(resource, params = {}) {
        return await Vue.axios.get(resource, {params});
    },

    async getFile(resource, params = {}) {
        try {
            return await Vue.axios.get(resource, {responseType: 'blob', params});
        } catch (e) {
            console.error(e);
        }
    },

    async get(resource, slug = '') {
        return await Vue.axios.get(`${resource}/${slug}`);
    },

    async post(resource, params = {}) {
        return await Vue.axios.post(`${resource}`, params);
    },

    async postFile(resource, params = {}) {
        try {
            return await Vue.axios.post(`${resource}`, params, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (e) {
            console.error(e);
        }
    },

    async update(resource, slug = '', params = {}) {
        return await Vue.axios.put(`${resource}/${slug}`, params);
    },

    async put(resource, params = {}) {
        return await Vue.axios.put(`${resource}`, params);
    },

    async delete(resource, params = {}) {
        return await Vue.axios.delete(resource, {params});
    },

    async refreshToken(config) {
        await store.dispatch(REFRESH_TOKEN, JwtService.getRefreshToken());
        config.headers.common.Authorization = `Bearer ${JwtService.getToken()}`;
        this.setHeader();
        return config;
    },

    getSid() {
        const domain = window.location.hostname.split('.').slice(window.location.hostname.split('.').length - 1)[0];
        return domainToSid[domain] ? domainToSid[domain] : domainToSid.com;
    },
};

export default ApiService;
