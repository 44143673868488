<template>
    <div class="container forms">
        <div class="row clearfix">
            <div class="twelvecol last">
                <div class="content">
                    <div class="mask" v-if="showMask"></div>
                    <!-- Tab-Navigation -->
                    <div id="original_tabs" class="clearfix">
                        <ul v-if="translations.strings">
                            <li @click="changeTab(0)" :class="{active: activeTab === 0}">
                                <a href="#tab0" rel="tab0" @click.prevent>
                                    <span>{{translations.strings['10495']}}</span>
                                </a>
                            </li>
                            <li @click="isAuthenticated ? changeTab(1) : null" :class="{disabled: !isAuthenticated, active: activeTab === 1}">
                                <a href="#tab1" rel="tab1" @click.prevent>
                                    <span>{{translations.strings['10496']}}</span>
                                </a>
                            </li>
                            <li @click="isAuthenticated ? changeTab(2) : null" :class="{disabled: !isAuthenticated, active: activeTab === 2}">
                                <a href="#tab2" rel="tab2" @click.prevent>
                                    <span>{{translations.strings['10497']}}</span>
                                </a>
                            </li>
                            <li @click="isAuthenticated ? changeTab(3) : null" :class="{disabled: !isAuthenticated, active: activeTab === 3}">
                                <a href="#tab3" rel="tab3" @click.prevent>
                                    <span>{{translations.strings['10516']}}</span>
                                </a>
                            </li>
                            <li @click="isAuthenticated ? changeTab(4) : null" :class="{disabled: !isAuthenticated, active: activeTab === 4}">
                                <a href="#tab4" rel="tab4" @click.prevent>
                                    <span>{{translations.strings['10498']}}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- /Tab-Navigation -->
                    <!-- Tab-Inhalte Container -->
                    <div id="original_tabs_content">
                        <!-- Tab 0 -->
                        <transition name="fade">
                            <MainForm v-show="activeTab === 0"/>
                        </transition>
                        <!-- /Tab 1 -->
                        <transition name="fade">
                            <CVForm v-show="activeTab === 1"/>
                        </transition>
                        <!-- /Tab 2 -->
                        <transition name="fade">
                            <SkillsForm v-show="activeTab === 2"/>
                        </transition>
                        <!-- /Tab 3 -->
                        <transition name="fade">
                            <JobOrientation v-show="activeTab === 3"/>
                        </transition>
                        <!-- /Tab 4 -->
                        <transition name="fade">
                            <QuestionsForm v-show="activeTab === 4"/>
                        </transition>
                    </div>
                    <!-- /Tab-Inhalte Container -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MainForm from '@/components/MainForm.vue';
    import CVForm from '@/components/CVForm.vue';
    import SkillsForm from '@/components/SkillsForm.vue';
    import JobOrientation from '@/components/JobOrientation.vue';
    import QuestionsForm from "../components/QuestionsForm";
    import { mapGetters } from 'vuex';
    import { CHANGE_TAB } from "../store/actions.type";

    export default {
        name: 'FormView',
        computed: {
            showMask() {
                return this.isLoading || this.isUserFetching;
            },
            ...mapGetters(['isLoading', 'isUserFetching', 'isAuthenticated', 'activeTab', 'translations'])
        },
        components: {
            QuestionsForm,
            MainForm,
            CVForm,
            JobOrientation,
            SkillsForm,
        },
        methods: {
            changeTab(tab) {
                this.$store.dispatch(CHANGE_TAB, tab)
            }
        },
        data() {
            return {
                release: false
            }
        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .content {
        position: relative;
    }
    .mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #EFEFEF;
        z-index: 100;
        /* margin: 40px 0px 30px; */
        opacity: 0.5;
    }
    .disabled {
        opacity: 0.5;
    }
    #original_tabs ul li.disabled a:hover {
        background: #1F1F1F;
        cursor: auto;
        color: #FFF !important;
    }
</style>
