export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setAuth';
export const SET_ERROR = 'setError';
export const FETCH_START = 'setLoading';
export const FETCH_END = 'setData';
export const SET_USER = 'setUser';
export const FETCH_USER_INFO_START = 'fetchUserInfoStart';
export const FETCH_USER_INFO_END = 'fetchUserInfoEnd';
export const SET_REFERENCES = 'setReferences';
export const SET_TRANSLATIONS = 'setTranslations';
export const SET_TAB = 'setTab';
export const SET_CV = 'setCV';
export const SET_MOODLE_DATA = 'setMoodleData';
export const ADD_CV_RECORD = 'addCVRecord';
export const UPDATE_CV_RECORD = 'updateCVRecord';
export const REMOVE_CV_ENTRY = 'removeCVRecord';
export const SET_SKILLS = 'setSkills';
export const SET_SKILL_VALUE = 'setSkillValue';
export const SET_USER_QUESTIONS = 'setUserQuestions';
export const SET_FILES_LIST = 'setFileList';
