<template>
    <div class="tab_content" id="tab3">
        <form class="formanizr">
            <legend v-if="translations.strings" v-text="translations.strings['10515']" />
            <fieldset v-if="references.final_questions">
                <div class="fzr_row fzr_label-top">
                    <div class="fzr_col fzr_4">
                        <p>Please check the matching answer options <strong>concerning your professional background</strong> as a registered nurse:</p>
                    </div>
                    <div class="fzr_col fzr_8">
                        <p>
                            <label for="q11">
                                <input id="q11" name="q1" type="checkbox" v-model="formData.nurse_registered">
                                {{ references.final_questions.nurse_registered }}
                            </label>
                            <label for="q12">
                                <input id="q12" name="q1" type="checkbox" v-model="formData.nurse_graduate">
                                {{ references.final_questions.nurse_graduate }}
                            </label>
                            <label for="q13">
                                <input id="q13" name="q1" type="checkbox" v-model="formData.nurse_over_2_years">
                                {{ references.final_questions.nurse_over_2_years }}
                            </label>
                            <label for="q14">
                                <input id="q14" name="q1" type="checkbox" v-model="formData.nurse_2_years">
                                {{ references.final_questions.nurse_2_years }}
                            </label>
                            <label for="q15">
                                <input id="q15" name="q1" type="checkbox" v-model="formData.nurse_none">
                                {{ references.final_questions.nurse_none }}
                            </label>
                            <label for="q16">
                                <input id="q16" name="q1" type="checkbox" v-model="formData.nurse_abroad">
                                {{ references.final_questions.nurse_abroad }}
                            </label>
                            <label for="q17">
                                <input id="q17" name="q1" type="checkbox" v-model="formData.nurse_currently">
                                {{ references.final_questions.nurse_currently }}
                            </label>
                        </p>
                    </div>
                </div>
                <div class="fzr_row fzr_label-top">
                    <div class="fzr_col fzr_4">
                        <p>Please check the matching answer options <strong>concerning your possible participation in free German language training</strong>:</p>
                    </div>
                    <div class="fzr_col fzr_8">
                        <p>
                            <label for="q21">
                                <input id="q21" name="q2" type="checkbox" v-model="formData.german_interested">
                                {{ references.final_questions.german_interested }}
                            </label>
                            <label for="q38">
                                <input id="q38" name="q2" type="checkbox" v-model="formData.ph_morning">
                                {{ references.final_questions.ph_morning }}
                            </label>
                            <label for="q22">
                                <input id="q22" name="q2" type="checkbox" v-model="formData.german_afternoon">
                                {{ references.final_questions.german_afternoon }}
                            </label>
                            <label for="q23">
                                <input id="q23" name="q2" type="checkbox" v-model="formData.german_evening">
                                {{ references.final_questions.german_evening }}
                            </label>
                            <label for="q24">
                                <input id="q24" name="q2" type="checkbox" v-model="formData.german_cagayan">
                                {{ references.final_questions.german_cagayan }}
                            </label>
                            <label for="q25">
                                <input id="q25" name="q2" type="checkbox" v-model="formData.german_internet">
                                {{ references.final_questions.german_internet }}
                            </label>
                        </p>
                    </div>
                </div>
                <div class="fzr_row fzr_label-top">
                    <div class="fzr_col fzr_4">
                        <p>Please check the matching answer options <strong>concerning your possible future work environment as a nurse</strong>:</p>
                    </div>
                    <div class="fzr_col fzr_8">
                        <p>
                            <label for="q31">
                                <input id="q31" name="q3" type="checkbox" v-model="formData.work_germany">
                                {{ references.final_questions.work_germany }}
                            </label>
                            <label for="q32">
                                <input id="q32" name="q3" type="checkbox" v-model="formData.work_hospital">
                                {{ references.final_questions.work_hospital }}
                            </label>
                            <label for="q33">
                                <input id="q33" name="q3" type="checkbox" v-model="formData.work_home_elderly">
                                {{ references.final_questions.work_home_elderly }}
                            </label>
                            <label for="q34">
                                <input id="q34" name="q3" type="checkbox" v-model="formData.work_home_care">
                                {{ references.final_questions.work_home_care }}
                            </label>
                            <label for="q35">
                                <input id="q35" name="q3" type="checkbox" v-model="formData.work_towns">
                                {{ references.final_questions.work_towns }}
                            </label>
                            <label for="q36">
                                <input id="q36" name="q3" type="checkbox" v-model="formData.work_passport">
                                {{ references.final_questions.work_passport }}
                            </label>
                            <label for="q37">
                                <input id="q37" name="q3" type="checkbox" v-model="formData.work_driving_license">
                                {{ references.final_questions.work_driving_license }}
                            </label>
                        </p>
                    </div>
                </div>
            </fieldset>
        </form>
        <form v-if="translations.strings" class="formanizr">
            <fieldset>
                <div class="fzr_row fzr_label-top">
                    <div class="fzr_col fzr_12 fzr_right">
                        <button @click.prevent="submitForm">Save</button>
                        <button @click.prevent="submitFormAndNext()" v-text="translations.strings['10499']" />
                    </div>
                </div>
            </fieldset>
        </form>
        <StepMenu :step="5"></StepMenu>
    </div>
</template>

<script>
    import {UPDATE_USER} from '../store/actions.type';
    import { mapGetters } from 'vuex';
    import { getToken } from '../common/jwt.service';
    import StepMenu from "@/components/StepMenu.vue";

    export default {
        name: "QuestionsForm",
      components: {StepMenu},
        computed: mapGetters(['questions', 'references', 'translations']),
        watch: {
            'questions': {
                handler() {
                    Object.keys(this.questions).map( (field) => {
                        if (this.formData.hasOwnProperty(field)) {
                            this.formData[field] = this.questions[field];
                        }
                    })
                },
            }
        },
        data() {
            return {
                formData: {
                    nurse_registered: false,
                    nurse_graduate: false,
                    nurse_over_2_years: false,
                    nurse_2_years: false,
                    nurse_none: false,
                    nurse_abroad: false,
                    nurse_currently: false,
                    german_interested: false,
                    ph_morning: false,
                    german_afternoon: false,
                    german_evening: false,
                    german_cagayan: false,
                    german_internet: false,
                    work_germany: false,
                    work_hospital: false,
                    work_home_elderly: false,
                    work_home_care: false,
                    work_towns: false,
                    work_passport: false,
                    work_driving_license: false
                },
            }
        },

        methods: {
            async submitForm() {
                this.$store.dispatch(UPDATE_USER, {form_part: 4, questions: this.formData});
                return true;
            },
            async submitFormAndNext() {
                if (await this.submitForm()) {
                    window.location = `${process.env.VUE_APP_JOB_SERVER ? process.env.VUE_APP_JOB_SERVER: 'https://test9.job-server.net'}/ang/login.php?sid=wcon_en&token=${getToken()}`
                }
            },
        }
    }
</script>

<style scoped>

</style>
