<template>
<form>
    <div class="fzr_row">
        <div class="fzr_col fzr_12">
            <div class="steps">
                <div
                    v-for="(i, key) in [1, 2, 3, 4, 5]"
                    :key="key"
                    class="number"
                    :class="{inverted: i == step}"
                >{{i}}</div>
                <div class="line"></div>
            </div>
        </div>
    </div>
</form>
</template>

<script>
export default {
  name: "StepMenu",
  props: ['step'],
}
</script>

<style scoped>

</style>