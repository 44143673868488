import ApiService from '@/common/api.service';
import {
    ADD_CV, DELETE_CV_ENTRY,
    GET_CV, UPLOAD_FILE, GET_FILES_LIST, DELETE_FILES,
} from './actions.type';
import {
    SET_CV,
    SET_ERROR,
    FETCH_START,
    FETCH_END, ADD_CV_RECORD, REMOVE_CV_ENTRY, SET_FILES_LIST,
} from './mutations.type';


const state = {
    cv: [],
    filesList: [],
};


const getters = {
    cv(state) {
        return state.cv;
    },
    filesList(state) {
        return state.filesList;
    },
};

const actions = {
    async [GET_CV](context) {
        context.commit(FETCH_START);
        try {
            const resp = await ApiService.query('/user/healthcare/cv');
            context.commit(SET_CV, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e.response);
        }
        context.commit(FETCH_END);
    },
    [ADD_CV](context, data) {
        context.commit(ADD_CV_RECORD, data);
    },
    [DELETE_CV_ENTRY](context, key) {
        context.commit(REMOVE_CV_ENTRY, key);
    },
    async [UPLOAD_FILE](context, uploadDoc) {
        context.commit(FETCH_START);
        const data = new FormData();
        data.append('upload_doc',  uploadDoc,  uploadDoc.name);
        try {
            await ApiService.postFile(`/user/healthcare/upload`, data);
            await context.dispatch(GET_FILES_LIST);
        } catch (e) {
            context.commit(SET_ERROR, e.response);
        }
        context.commit(FETCH_END);
    },
    async [GET_FILES_LIST](context) {
        context.commit(FETCH_START);
        try {
            const resp = await ApiService.query('/user/healthcare/uploaded_files');
            context.commit(SET_FILES_LIST, resp.data);
        } catch (e) {
            context.commit(SET_ERROR, e.response);
        }
        context.commit(FETCH_END);
    },
    async [DELETE_FILES](context, data) {
        context.commit(FETCH_START);
        try {
            await ApiService.delete('/user/healthcare/uploaded_files', {'files': data});
            await context.dispatch(GET_FILES_LIST);
        } catch (e) {
            context.commit(SET_ERROR, e.response);
        }
        context.commit(FETCH_END);
    }
};

const mutations = {
    [SET_CV](state, data) {
        state.cv = data.map((item, index) => {
            item.key = index;
            return item;
        });
    },
    [ADD_CV_RECORD](state, data) {
        let tmp = Object.assign([], state.cv);
        if (data.hasOwnProperty('index')) {
            tmp.splice(data.index, 1);
        }
        if (data.hasOwnProperty('key')) {
            let itemIndex = null;
            tmp.map( (record, index) => {
                if (record.key === data.key) {
                    itemIndex = index;
                }
            });
            tmp.splice(itemIndex, 1);
        } else {
            data.key = Math.max(...tmp.map(function(record) { return record.key; }), 0) + 1;
        }
        state.cv = tmp.concat(Object.assign({}, data));
    },
    [REMOVE_CV_ENTRY](state, key) {
        let tmp = Object.assign([], state.cv);
        let itemIndex = null;
        tmp.map( (record, index) => {
            if (record.key === key) {
                itemIndex = index;
            }
        });
        tmp.splice(itemIndex, 1);
        state.cv = Object.assign([], tmp);
    },
    [SET_FILES_LIST](state, data) {
        state.filesList = data.files;
    }
};

export default {
    state,
    actions,
    mutations,
    getters,
};
