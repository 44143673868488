//eslint-disable-line
<template>
    <div class="tab_content" id="tab2">
        <form class="formanizr">
            <legend v-if="translations.strings" v-text="translations.strings['10510']" />
            <fieldset>
                <div class="section">
                    <div class="fzr_row fzr_label-top">
                        <div class="fzr_col fzr_3">
                            <p><strong>Languages:</strong></p>
                        </div>
                        <div class="fzr_col fzr_9" v-if="references.skills">
                            <ul class="skills-list">
                                <li v-for="(skill, index) in references.skills.language" :key="index">
                                    <SkillContainer
                                            @updateSkill="updateSKill(skill.id, $event)"
                                            :value="skills[skill.id]"
                                            :skillId="skill.id"
                                            :title="skill.title"
                                    />
                               </li>
                           </ul>
                        </div>
                    </div>
                </div>
                <div class="section">
                    <div class="fzr_row fzr_label-top">
                        <div class="fzr_col fzr_3">
                            <p><strong>Driving licenses:</strong></p>
                        </div>
                        <div class="fzr_col fzr_9" v-if="references.skills">
                            <ul class="skills-list">
                                <li v-for="(skill, index) in references.skills.dl" :key="index">

                                    <label><input type="checkbox" :checked="skills[skill.id]" @change="updateSKill(skill.id, $event.target.checked)">{{skill.title}}</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="section" v-if="references.skills.competence.essential_knowledge && references.skills.competence.essential_knowledge.length">
                    <div class="fzr_row fzr_label-top">
                        <div v-if="translations.strings" class="fzr_col fzr_3">
                            <p><strong>{{ translations.strings['10511'] }}:</strong></p>
                        </div>
                        <div class="fzr_col fzr_9">
                            <ul class="skills-list">
                                <li v-for="(skill, index) in references.skills.competence.essential_knowledge" :key="index">
                                    <SkillContainer
                                            @updateSkill="updateSKill(skill.id, $event)"
                                            :value="skills[skill.id]"
                                            :skillId="skill.id"
                                            :title="skill.title"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="section" v-if="references.skills.competence.essential_skills && references.skills.competence.essential_skills.length">
                    <div class="fzr_row fzr_label-top">
                        <div v-if="translations.strings" class="fzr_col fzr_3">
                            <p><strong>{{ translations.strings['10512'] }}:</strong></p>
                        </div>
                        <div class="fzr_col fzr_9">
                            <ul class="skills-list">
                                <li v-for="(skill, index) in references.skills.competence.essential_skills" :key="index">
                                    <SkillContainer
                                            @updateSkill="updateSKill(skill.id, $event)"
                                            :value="skills[skill.id]"
                                            :skillId="skill.id"
                                            :title="skill.title"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="section" v-if="references.skills.competence.optional_knowledge && references.skills.competence.optional_knowledge.length">
                    <div class="fzr_row fzr_label-top">
                        <div v-if="translations.strings" class="fzr_col fzr_3">
                            <p><strong>{{ translations.strings['10513'] }}:</strong></p>
                        </div>
                        <div class="fzr_col fzr_9">
                            <ul class="skills-list">
                                <li v-for="(skill, index) in references.skills.competence.optional_knowledge" :key="index">
                                    <SkillContainer
                                            @updateSkill="updateSKill(skill.id, $event)"
                                            :value="skills[skill.id]"
                                            :skillId="skill.id"
                                            :title="skill.title"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="section" v-if="references.skills.competence.optional_skills && references.skills.competence.optional_skills.length">
                    <div class="fzr_row fzr_label-top">
                        <div v-if="translations.strings" class="fzr_col fzr_3">
                            <p><strong>{{ translations.strings['10514'] }}:</strong></p>
                        </div>
                        <div class="fzr_col fzr_9" >
                            <ul class="skills-list">
                                <li v-for="(skill, index) in references.skills.competence.optional_skills" :key="index">
                                    <SkillContainer
                                            @updateSkill="updateSKill(skill.id, $event)"
                                            :value="skills[skill.id]"
                                            :skillId="skill.id"
                                            :title="skill.title"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
        <form class="formanizr">
            <fieldset>
                <div class="fzr_row fzr_label-top">
                    <div v-if="translations.strings" class="fzr_col fzr_12 fzr_right">
                        <button @click.prevent="submitForm">Save</button>
                        <button @click.prevent="submitFormAndNext()" v-text="translations.strings['10499']"></button>
                    </div>
                </div>
            </fieldset>
        </form>
        <StepMenu :step="3"></StepMenu>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { UPDATE_USER, CHANGE_TAB, UPDATE_SKILL } from "../store/actions.type";
    import SkillContainer from './SkillContainer';
    import StepMenu from "@/components/StepMenu.vue";


    export default {
        name: "SkillsForm",
        components: {StepMenu, SkillContainer},
        computed: mapGetters(['references', 'translations', 'activeTab', 'skills']),

        methods: {
            skillFormatter(skillId) {

                try {
                    const tmp = parseInt(this.formData[skillId]);
                    this.$nextTick(() => {
                        this.formData[skillId] =  tmp > 5 ? 5 : tmp;
                    })

                } catch (e) {
                    this.$nextTick(() => {
                        this.formData[skillId] = '';
                    })
                }
            },
            updateSKill(skillId, value) {
                this.$store.dispatch(UPDATE_SKILL, {skillId, value})
            },
            async submitForm() {
                this.$store.dispatch(UPDATE_USER, {form_part: 3, skills: this.skills});
                return true;
            },
            async submitFormAndNext() {
                if (await this.submitForm()) {
                    this.$store.dispatch(CHANGE_TAB, this.activeTab + 1);
                }
            },
        }
    }
</script>

<style>
    .skills-list {
        column-count: 3;
        -webkit-column-count: 3;
        -moz-column-count: 3;
        margin: 0;
    }

    .skill-value {
        width: 55px !important;
        margin-right: 5px
    }

    @media handheld, only screen and (max-width: 767px) {
        .skills-list {
            column-count: 1;
        }
        .skill-value {
            width: 25px !important;
            margin-right: 5px !important;
        }
    }

</style>
