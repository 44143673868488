import Vue from 'vue';
import Vuex from 'vuex';
import app from './app.module.js';
import cv from './cv.module.js';
import skills from './skills.module.js';
import questions from './questions.module.js';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        cv,
        skills,
        questions
    },
});
