export const LOGIN = 'login';
export const REFRESH_TOKEN = 'refresh';
export const LOGOUT = 'logout';
export const CHECK_AUTH = 'checkAuth';
export const GET_USER = 'getUser';
export const UPDATE_USER = 'updateUser';
export const REGISTRATION = 'registration';
export const GET_REFERENCES = 'getReferences';
export const GET_TRANSLATIONS = 'getTranslations';
export const CHANGE_TAB = 'changeTab';
export const GET_CV = 'getCV';
export const ADD_CV = 'addCV';
export const DELETE_CV_ENTRY = 'deleteCVEntry';
export const UPDATE_SKILL = 'updateSkill';
export const UPLOAD_FILE = 'uploadFile';
export const GET_FILES_LIST = 'getFilesList';
export const DELETE_FILES = 'deleteFiles';
export const START_MOODLE_COURSE = 'startMoodleCourse';